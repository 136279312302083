import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import copyToClipboard from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import { useParams, Route, Routes } from 'react-router';
import LaunchIcon from '@material-ui/icons/Launch';
import { useNavigate } from 'react-router-dom';
import first from 'lodash/first';
import { getUIItem, setUIItem } from '@pv/common/utils';
import { PageLoading } from '@pv/common/components';
import { useChipStyles } from './useChipStyles';
import { adminVenueQuery } from './graphql';
import {
  AnalyticsPanel,
  DangerPanel,
  DataImportsPanel,
  EventsPanel,
  InfoPanel,
  ProposalPaymentsChip,
  SquareChip,
  StripeChip,
  UsersPanel,
  MessagesPanel,
  FeatureFlagsPanel,
} from './components';
import { MenuUpload } from './components/MenuUpload';
import { LegacyChip } from './components/Chips';
import { SubscriptionPanel } from './components/SubscriptionPanel';

const tabKey = 'venue-tab';
const saveMostRecentlyVisitedTab = (tab) => {
  setUIItem(tabKey, tab);
};

const getMostRecentlyVisitedTab = () => {
  return getUIItem(tabKey, 'info');
};

export const Venue = () => {
  const classes = useChipStyles();
  const theme = useTheme();

  const params = useParams();
  const navigate = useNavigate();
  const venueId = params.id;
  const variables = { id: venueId };
  const { data } = useQuery(adminVenueQuery, { variables });
  const venue = data?.adminVenue;

  const tab = params.tab;

  React.useEffect(() => {
    if (!tab) {
      const t = getMostRecentlyVisitedTab();
      navigate(`/admin/venues/${venueId}/${t}`);
    }
  }, [tab, navigate, venueId]);

  const onTabChange = (e, t) => {
    saveMostRecentlyVisitedTab(t);
    navigate(`/admin/venues/${venueId}/${t}`);
  };

  if (!venue) {
    return <PageLoading />;
  }

  const onClickLoginAsUser = ({ row }) => {
    const user = first(venue.users);
    window.open(user.magicLoginLink, 'loginasuser');
  };

  const onClickIdChip = () => {
    copyToClipboard(venue.id);
    enqueueSnackbar(`Copied ${venue.id} to clipboard`, {
      variant: 'info',
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          <Chip
            clickable
            onClick={onClickIdChip}
            variant="outlined"
            label={`ID ${venue.id}`}
          />
          <Typography className={classes.name} variant="h1">
            Venue: {venue?.name}
          </Typography>
          <StripeChip venue={venue} />
          <SquareChip venue={venue} />
          <ProposalPaymentsChip venue={venue} />
          <LegacyChip subscription={venue.subscription} />
        </div>
        <Button onClick={onClickLoginAsUser}>
          <LaunchIcon />
        </Button>
      </div>
      <div style={{ marginBottom: theme.spacing(2) }}>
        <Typography>
          Organization:{' '}
          <Link
            className={classes.link}
            href={`/admin/organizations/${venue?.organization?.id}`}
          >
            {venue.organization?.name}
          </Link>
        </Typography>
      </div>
      <Paper>
        <div className={classes.tabWrapper}>
          <div style={{ flexShrink: 1 }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab}
              onChange={onTabChange}
              className={classes.tabs}
            >
              <Tab value="info" label="Info" />
              <Tab value="feature-flags" label="Feature Flags" />
              <Tab value="users" label="Users" />
              <Tab value="events" label="Events" />
              <Tab value="subscription" label="Subscription" />
              <Tab value="analytics" label="Analytics" />
              <Tab value="data_imports" label="Data Imports" />
              <Tab value="menu-upload" label="Menu Upload" />
              <Tab value="messages" label="Messages" />
              <Tab value="danger" label="Danger" />
            </Tabs>
          </div>
          <div style={{ width: '100%', flexGrow: 1, minHeight: 500 }}>
            <Routes>
              <Route
                path={'info'}
                index
                element={<InfoPanel venue={venue} />}
              />
              <Route
                path={'feature-flags'}
                element={<FeatureFlagsPanel venue={venue} />}
              />
              <Route path={'users'} element={<UsersPanel venue={venue} />} />
              <Route path={'events'} element={<EventsPanel venue={venue} />} />
              <Route
                path={'subscription'}
                element={<SubscriptionPanel venue={venue} />}
              />
              <Route
                path={'analytics'}
                element={<AnalyticsPanel venue={venue} />}
              />
              <Route
                path={'data_imports'}
                element={<DataImportsPanel venueId={venue.id} />}
              />
              <Route
                path="menu-upload"
                element={<MenuUpload venue={venue} />}
              />
              <Route
                path={'messages'}
                element={<MessagesPanel venueId={venue.id} />}
              />
              <Route path={'danger'} element={<DangerPanel venue={venue} />} />
            </Routes>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Venue;
